export const HANDLE_LOAD_BOOKINGS = "HANDLE_LOAD_BOOKINGS";
export const HANDLE_UPDATE_BOOKING_STATUS = "HANDLE_UPDATE_BOOKING_STATUS";
export const SET_BOOKINGS = "SET_BOOKINGS";
export const HANDLE_LOAD_All_SERVICES = "HANDLE_LOAD_All_SERVICES";
export const SET_All_SERVICES = "SET_All_SERVICES";
export const HANDLE_UPDATE_SERVICE_PRICE = "HANDLE_UPDATE_SERVICE_PRICE";
export const HANDLE_UPDATE_ADDON_PRICE = "HANDLE_UPDATE_ADDON_PRICE";
export const HANDLE_LOAD_All_DISABLED_SLOTS = "HANDLE_LOAD_All_DISABLED_SLOTS";
export const SET_All_DISABLED_SLOTS = "SET_All_DISABLED_SLOTS";
export const HANDLE_ADD_DISABLED_SLOT = "HANDLE_ADD_DISABLED_SLOT";
export const HANDLE_UPDATE_DISABLED_SLOT = "HANDLE_UPDATE_DISABLED_SLOT";
export const HANDLE_DELETE_DISABLED_SLOT = "HANDLE_DELETE_DISABLED_SLOT";
