import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from '@mui/icons-material/LocationOn';

export const contactInfo = [
  {
    icon: <PhoneIcon sx={{ color: "primary", mr: 1 }} />,
    label: "+1 587-999-8722",
    value: "tel:+1 587-999-8722",
  },
  {
    icon: <LocationOnIcon sx={{ color: "primary", mr: 1 }} />,
    value: "11124 36 St NE unit 3040, Calgary, AB T3N 1L3, Canada",
    label: "11124 36 St NE unit 3040, Calgary, AB T3N 1L3, Canada",
  },
];
