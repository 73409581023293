import { fetch } from "./http";

const controller: string = "login";

export const login = async (payload: any) => {
  try {
    const response = await fetch.Post(controller + "", payload);
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 450) {
      const data = await response.json();
      throw new Error(data);
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error: any) {
    throw error;
  }
};

export const logout = async () => {
  try {

  } catch (error) {
    throw error;
  }
};