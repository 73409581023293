import { reviewsRoot } from "../../interfaces/reviews.interfaces";
import {
  CREATE_ORDER,
  FETCH_DISABLED_SLOTS,
  FETCH_SERVICES,
  INITIALIZE_PAYMENT,
  SET_DISABLED_SLOTS,
  SET_SERVICES,
} from "./types";

export const fetchServices = () => {
  return {
    type: FETCH_SERVICES,
  };
};

export const setServices = (payload: reviewsRoot) => {
  return {
    type: SET_SERVICES,
    payload: payload,
  };
};

export const fetchDisabledSlots = () => {
  return {
    type: FETCH_DISABLED_SLOTS,
  };
};

export const setDisabledSlots = (payload: reviewsRoot) => {
  return {
    type: SET_DISABLED_SLOTS,
    payload: payload,
  };
};

export const initializePayment = (payload: { categoryId: string; serviceId: string; addons: string[]; totalAmount: number }) => {
  return {
    type: INITIALIZE_PAYMENT,
    payload
  };
};

export const createOrder = (payload: any) => {
  return {
    type: CREATE_ORDER,
    payload
  };
};
