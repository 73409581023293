import type { PayloadAction } from "@reduxjs/toolkit";
import { SET_DISABLED_SLOTS, SET_SERVICES } from "./types";
import { categoryProps, disabledSlotProps } from "src/interfaces";

export const services = (
  state: categoryProps[] = [],
  action: PayloadAction<any> = null
): categoryProps[] => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_SERVICES: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

export const disabledSlots = (
  state: disabledSlotProps = {},
  action: PayloadAction<any> = null
): disabledSlotProps => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_DISABLED_SLOTS: {
      return payload;
    }
    default: {
      return state;
    }
  }
};
