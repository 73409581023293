import { useEffect } from "react";
import NProgress from "nprogress";
import { Box, CircularProgress } from "@mui/material";
import { connect } from "react-redux";
import { loaderProps } from "src/interfaces";

function SuspenseLoader({ loader: { visible } }: { loader: loaderProps }) {
  // useEffect(() => {
  //   NProgress.start();
  //   return () => {
  //     NProgress.done();
  //   };
  // }, []);
  if (visible) {
    return null;
  }
  return (
    <Box
      sx={{
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress color="secondary" size={64} disableShrink thickness={1} />
    </Box>
  );
}

const mapStateToProps = ({ loader }) => ({
  loader,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(SuspenseLoader);
