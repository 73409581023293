import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import DesignServicesTwoToneIcon from "@mui/icons-material/DesignServicesTwoTone";

type SidebarMenuObject = {
  title?: string;
  path?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  children?: SidebarMenuObject[];
};

const menus = (role?: string): SidebarMenuObject[] => {
  let menuList: SidebarMenuObject[] = [];
  if (role === "admin") {
    menuList.push(
      // {
      //   title: "Dashboard",
      //   path: "/admin/dashboard",
      //   startIcon: <DesignServicesTwoToneIcon />,
      // },
      {
        title: "Manage",
        path: "/admin",
        children: [
          {
            title: "Bookings",
            path: "/bookings",
            startIcon: <VerifiedUserIcon />,
          },
        ],
      },
      {
        title: "Services",
        path: "/admin",
        children: [
          {
            title: "Categories",
            path: "/categories",
            startIcon: <VerifiedUserIcon />,
          },
          {
            title: "Services",
            path: "/services",
            startIcon: <VerifiedUserIcon />,
          },
          {
            title: "Addons",
            path: "/addons",
            startIcon: <VerifiedUserIcon />,
          },
          {
            title: "Slots Disabling",
            path: "/disabled-slots",
            startIcon: <VerifiedUserIcon />,
          },
        ],
      }
    );
  }
  if (role === "subscriber") {
    menuList.push({
      title: "Manage",
      path: "/manage",
      children: [
        {
          title: "Setting",
          path: "/setting",
          startIcon: <VerifiedUserIcon />,
        },
      ],
    });
  }
  return menuList;
};

export default menus;
