import { fetch } from "./http";

const controller: string = "payment/";

export const initializePaymentService = async (payload: { categoryId: string; serviceId: string; addons: string[]; totalPrice: number }) => {
  try {
    const response = await fetch.Post(controller + "initialize", payload);

    if (response.status === 200) {
      const data = await response.json();
      return data?.client_secret;
    } else if (response.status === 450) {
      const data = await response.json();
      throw new Error(data);
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error: any) {
    throw error;
  }
};
