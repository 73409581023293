import { FC, ReactNode, useEffect } from "react";
import { Box, alpha, lighten, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/website/css/style.css";
import "../../assets/website/css/aos.css";
import "../../assets/website/css/slick-theme.css";
import "../../assets/website/css/slick.css";
import "../../assets/website/css/text-animation.css";
import "../../assets/website/css/style.css";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import $ from "jquery";
import "slick-carousel/slick/slick.min.js";

interface SidebarLayoutProps {
  children?: ReactNode;
}

const BaseLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();

  useEffect(() => {
    // For mobile menu
    const mobileBarClickHandler = () => {
      document.querySelector(".menu-navigation")?.classList.toggle("show-menu");
      document.querySelector(".mobile-bar")?.classList.toggle("close-menu");
    };
    document
      .querySelector(".mobile-bar")
      ?.addEventListener("click", mobileBarClickHandler);

    // For header sticky
    const scrollHandler = () => {
      const scroll = window.scrollY;
      const topheader = document.querySelector(".topheader");
      if (topheader) {
        if (scroll > 1) {
          topheader.classList.add("stckty-header");
        } else {
          topheader.classList.remove("stckty-header");
        }
      }
    };
    window.addEventListener("scroll", scrollHandler);

    // For animation on scroll page
    AOS.init({
      easing: "ease-out-back",
      duration: 3000,
    });

    // For after and before images

    const moveDivisor1 = () => {
      const handle1 = document.getElementById("handle1") as HTMLInputElement;
      const divisor1 = document.getElementById("divisor1");
      const slider1 = document.getElementById("slider1") as HTMLInputElement;
      if (handle1 && divisor1 && slider1) {
        handle1.style.left = (slider1.value || "0") + "%";
        divisor1.style.width = (slider1.value || "0") + "%";
      }
    };
    window.onload = moveDivisor1;

    return () => {
      document
        .querySelector(".mobile-bar")
        ?.removeEventListener("click", mobileBarClickHandler);
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: "100%",
          ".MuiPageTitle-wrapper": {
            background: theme.colors.alpha.white[100],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === "dark"
                ? `0 1px 0 ${alpha(
                    lighten(theme.colors.primary.main, 0.7),
                    0.15
                  )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                    theme.colors.alpha.secondary[100],
                    0.1
                  )}, 0px 5px 12px -4px ${alpha(
                    theme.colors.alpha.secondary[100],
                    0.05
                  )}`,
          },
        }}
      >
        <Header />
        <Sidebar />
        <Box
          sx={{
            minHeight: "89%",
            position: "relative",
            zIndex: 5,  
            display: "block",
            flex: 1,
          }}
        >
          <Box
            sx={{
              height: "100%",
              minHeight: 200,
              backgroundColor: "white",
            }}
            display="block"
          >
            <Outlet />
          </Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default BaseLayout;
