import { call, put, takeLatest } from "redux-saga/effects";
import {} from "../../interfaces";
import { FETCH_GOOGLE_REVIEWS } from "./types";
import { googleReviews } from "../../services";
import { setGoogleReview } from "./actions";
import { reviews } from "src/mockData/reviews";

function* handleGoogleReviews(): Generator<any> {
  try {
    // const responseData: any = yield call(googleReviews);
    yield put(setGoogleReview(reviews));
  } catch (error) {}
}

export function* additionalSaga() {
  yield takeLatest<any>(FETCH_GOOGLE_REVIEWS, handleGoogleReviews);

}
