import { call, put, takeLatest } from "redux-saga/effects";
import {
  loginProps,
} from "../../interfaces";
import {
  HANDLE_LOGIN,
  REMOVE_AUTH_USER,
} from "./types";
import {
  login,
  logout,
} from "../../services";
import { setAuthUser } from "./actions";
import { setAlert } from "../alert";
import { removeLoader, setLoader } from "../loader";
import { setCurrentRoute } from "../router";

function* handleLogin({ payload }: { payload: loginProps }): Generator<any> {
  yield put(setLoader({ transparent: true }));
  try {
    const responseData: any = yield call(login, payload);
    yield put(setAuthUser({ ...responseData, isLoggedIn: true }));
    yield put(setCurrentRoute({ path: '/admin/bookings' }));
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLogout() {
  yield put(setLoader({ transparent: true }));
  try {
    yield call(logout);
    yield put(setCurrentRoute({ path: '/' }));
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}


export function* authSaga() {
  yield takeLatest<any>(HANDLE_LOGIN, handleLogin);
  yield takeLatest<any>(REMOVE_AUTH_USER, handleLogout);
}
