import { removeAuthUser, store } from "src/store";
import { fetch } from "./http";

const controller: string = "admin/";

export const loadBookingsService = async () => {
  try {
    const response = await fetch.Get(controller + "bookings");
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 450) {
      const data = await response.json();
      throw new Error(data);
    } else if (response.status === 401 || response.status === 403) {
      store?.dispatch(removeAuthUser())
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error: any) {
    throw error;
  }
};

export const updateBookingStatusService = async (payload: any) => {
  try {
    const response = await fetch.Put(controller + "booking", payload);
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 450) {
      const data = await response.json();
      throw new Error(data);
    } else if (response.status === 401 || response.status === 403) {
      store?.dispatch(removeAuthUser())
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error: any) {
    throw error;
  }
};

export const loadCategoriesService = async () => {
  try {
    const response = await fetch.Get(controller + "categories");
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 450) {
      const data = await response.json();
      throw new Error(data);
    } else if (response.status === 401 || response.status === 403) {
      store?.dispatch(removeAuthUser())
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error: any) {
    throw error;
  }
};

export const updateServicePriceService = async (payload: any) => {
  try {
    const response = await fetch.Put(controller + "service", payload);
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 450) {
      const data = await response.json();
      throw new Error(data);
    } else if (response.status === 401 || response.status === 403) {
      store?.dispatch(removeAuthUser())
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error: any) {
    throw error;
  }
}

export const updateAddOnPriceService = async (payload: any) => {
  try {
    const response = await fetch.Put(controller + "add_on", payload);
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 450) {
      const data = await response.json();
      throw new Error(data);
    } else if (response.status === 401 || response.status === 403) {
      store?.dispatch(removeAuthUser())
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error: any) {
    throw error;
  }
}

export const loadAllDisabledSlotsService = async () => {
  try {
    const response = await fetch.Get(controller + "disabled_slots");

    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 450) {
      const data = await response.json();
      throw new Error(data);
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error: any) {
    throw error;
  }
};

export const addDisabledSlotService = async (payload: any) => {
  try {
    const response = await fetch.Post(controller + "disabled_slot", payload);
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 450) {
      const data = await response.json();
      throw new Error(data);
    } else if (response.status === 401 || response.status === 403) {
      store?.dispatch(removeAuthUser())
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error: any) {
    throw error;
  }
}

export const updateDisabledSlotService = async (payload: any) => {
  try {
    const response = await fetch.Put(controller + "disabled_slot", payload);
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 450) {
      const data = await response.json();
      throw new Error(data);
    } else if (response.status === 401 || response.status === 403) {
      store?.dispatch(removeAuthUser())
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error: any) {
    throw error;
  }
}

export const deleteDisabledSlotService = async (id: any) => {
  try {
    const response = await fetch.Delete(controller + `disabled_slot?id=${id}`);
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 450) {
      const data = await response.json();
      throw new Error(data);
    } else if (response.status === 401 || response.status === 403) {
      store?.dispatch(removeAuthUser())
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error: any) {
    throw error;
  }
}
