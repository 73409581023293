import { call, delay, put, takeLatest } from "redux-saga/effects";
import { CREATE_ORDER, FETCH_DISABLED_SLOTS, FETCH_SERVICES, INITIALIZE_PAYMENT } from "./types";
import { carServicesService, createOrderService, disabledSlotsService, initializePaymentService } from "../../services";
import { setDisabledSlots, setServices } from "./actions";
import { setBookingModel, setClientSecret } from "../additional";
import { removeLoader, setLoader } from "../loader";
import { setAlert, store } from "../";

function* handleServices(): Generator<any> {
  try {
    const responseData: any = yield call(carServicesService);
    yield put(setServices(responseData));
  } catch (error) { }
}

function* handleDisabledSlots(): Generator<any> {
  try {
    const responseData: any = yield call(disabledSlotsService);
    yield put(setDisabledSlots(responseData));
  } catch (error) { }
}

function* handleInitializePayment({ payload }: { payload: any }): Generator<any> {
  try {
    yield (setLoader({ visible: true, transparent: true }))
    const responseData: any = yield call(initializePaymentService, payload);
    yield put(setClientSecret(responseData));
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: 'error' }))
  }
  yield (removeLoader())
}

function* handleCreateOrder({ payload }: { payload: any }): Generator<any> {
  try {
    yield (setLoader({ visible: true, transparent: true }))
    const responseData: any = yield call(createOrderService, payload);
    const model = store?.getState()?.bookingModel;
    yield put(setBookingModel({ ...model, ...payload, isConfirmed: true }));
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: 'error' }))
  }
  yield delay(100)
  yield (removeLoader())
}

export function* bookingSaga() {
  yield takeLatest<any>(FETCH_SERVICES, handleServices);
  yield takeLatest<any>(FETCH_DISABLED_SLOTS, handleDisabledSlots);
  yield takeLatest<any>(INITIALIZE_PAYMENT, handleInitializePayment);
  yield takeLatest<any>(CREATE_ORDER, handleCreateOrder);
}
