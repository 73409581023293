import { useContext, useEffect } from "react";
import Scrollbar from "src/components/Scrollbar";
import { SidebarContext } from "src/contexts/SidebarContext";

import {
  Box,
  Drawer,
  alpha,
  styled,
  Divider,
  useTheme,
  Button,
  lighten,
  darken,
} from "@mui/material";

import SidebarMenu from "./SidebarMenu";
import Logo from "src/components/LogoDark";
import LogoWhite from "src/components/LogoWhite";
import { authUserProps } from "src/interfaces";
import { removeAuthUser } from "src/store";
import { connect } from "react-redux";

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme?.admin?.sidebar.width};
        min-width: ${theme?.admin?.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
`
);

const Sidebar = (props: { signOut: Function; auth: authUserProps }) => {
  const { signOut, auth } = props;
  const { sidebarToggle, openSidebar, closeSidebar } =
    useContext(SidebarContext);
  const theme = useTheme();
  // const width = document?.body?.clientWidth;

  // useEffect(() => {
  //   if (width > 991) {
  //     openSidebar();
  //   } else {
  //     closeSidebar();
  //   }
  // }, [width]);
  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: "none",
            lg: "inline-block",
          },
          position: "fixed",
          left: 0,
          top: 0,
          background:
            theme.palette.mode === "dark"
              ? alpha(lighten(theme?.admin?.header.background, 0.1), 0.5)
              : darken(theme.colors.alpha.secondary[100], 0.5),
          boxShadow:
            theme.palette.mode === "dark"
              ? theme?.admin?.sidebar.boxShadow
              : "none",
        }}
      >
        <Scrollbar>
          <Box mt={3}>
            <Box
              sx={{
                width: "60%",
                m: 'auto'
              }}
            >
              <LogoWhite />
            </Box>
          </Box>
          <Divider
            sx={{
              mt: theme.spacing(3),
              mx: theme.spacing(2),
              background: theme.colors.alpha.trueWhite[10],
            }}
          />
          <SidebarMenu />
        </Scrollbar>
        <Divider
          sx={{
            background: theme.colors.alpha.trueWhite[10],
          }}
        />
        <Box p={2}>
          <Button
            href="#"
            onClick={() => signOut()}
            rel="noopener noreferrer"
            variant="contained"
            color="warning"
            size="small"
            fullWidth
          >
            Logout
          </Button>
        </Box>
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme?.admin?.sidebar.boxShadow}`,
        }}
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background:
              theme.palette.mode === "dark"
                ? theme.colors.alpha.white[100]
                : darken(theme.colors.alpha.secondary[100], 0.5),
          }}
        >
          <Scrollbar>
            <Box mt={3}>
              <Box
                sx={{
                  width: "60%",
                  m: 'auto'
                }}
              >
                <LogoWhite />
              </Box>
            </Box>
            <Divider
              sx={{
                mt: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10],
              }}
            />
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

const mapStateToProps = ({ auth }) => ({ auth });
const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(removeAuthUser()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
