import { loginProps } from "../../interfaces";
import {
  HANDLE_LOGIN,
  SET_AUTH_USER,
  REMOVE_AUTH_USER,
} from "./types";

export const login = (payload: loginProps) => {
  return {
    type: HANDLE_LOGIN,
    payload,
  };
};
export const setAuthUser = (payload: any) => {
  return {
    type: SET_AUTH_USER,
    payload,
  };
};
export const removeAuthUser = () => {
  return {
    type: REMOVE_AUTH_USER,
  };
};
