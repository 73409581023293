import type { PayloadAction } from "@reduxjs/toolkit";
import {
  RESET_BOOKING_MODEL,
  SET_BOOKING_MODEL,
  SET_CLIENT_SECRET,
  SET_GOOGLE_REVIEWS,
} from "./types";
import { stripeProps, reviewsRoot, bookingModelProps } from "src/interfaces";
import { reviews } from "src/mockData/reviews";

export const googleReviews = (
  state: reviewsRoot = reviews,
  action: PayloadAction<any> = null
): reviewsRoot => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_GOOGLE_REVIEWS: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

export const stripeModel = (
  state: stripeProps = { clientSecret: null },
  action: PayloadAction<any> = null
): stripeProps => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_CLIENT_SECRET: {
      return { ...state, clientSecret: payload };
    }
    default: {
      return state;
    }
  }
};

export const bookingModel = (
  state: bookingModelProps = {},
  action: PayloadAction<bookingModelProps> = null
): bookingModelProps => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_BOOKING_MODEL: {
      return payload;
    }
    case RESET_BOOKING_MODEL: {
      return {};
    }
    default: {
      return state;
    }
  }
};
