import { Box, Container, Link, Typography, styled } from "@mui/material";

const FooterWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(4)};
`
);

function Footer() {
  return (
    <FooterWrapper className="footer-wrapper">
      <Box
        pb={4}
        display="block"
        alignItems="center"
        textAlign="center"
        justifyContent="space-between"
        bottom={0}
      >
        <Typography
          sx={{
            pt: { xs: 2, md: 0 },
          }}
          variant="subtitle1"
        >
          &copy; 2022 - T-Bros Powered by{" "}
          <Link
            href="https://cyberace.ca/"
            target="_blank"
            rel="noopener noreferrer"
            color="secondary"
            sx={{ textDecoration: "none" }}
          >
            Cyber Ace
          </Link>
        </Typography>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
