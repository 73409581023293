import { Box, List, ListItem, ListItemText } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { menus } from "../../constants";
import { useState } from "react";

const ListWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(["color", "fill"])};
            
            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                            transition: all .2s;
                            // background: ${theme.colors.primary.main};
                    }
                }

                &.active,
                &:active,
                &:hover {
                    transition: all .2s;
                    .MuiTypography-root{
                      color:${theme?.colors?.secondary.main};
                    }
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
        .dropdown-list {
          display: none;
          position: absolute;
          top: 100%;
          left: 0;
          z-index: 1;
      
          background-color: ${theme.colors.primary.main};  
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          padding: ${theme.spacing(1)};
          list-style: none;
          margin: 0;
          min-width: 160px;

          &.show {
              display: block;
          }

          .MuiListItem-root {
              padding: ${theme.spacing(1)};
          }
      }

`
);

function HeaderMenu({ color }: { color?: string }) {
  const theme = useTheme();
  const [isServicesDropdownVisible, setServicesDropdownVisible] =
    useState(false);
  const location = useLocation();
  const handleServicesMouseEnter = () => {
    setServicesDropdownVisible(true);
  };

  const handleServicesMouseLeave = () => {
    setServicesDropdownVisible(false);
  };

  return (
    <>
      <ListWrapper
        sx={{
          display: {
            xs: "none",
            md: "block",
          },
        }}
      >
        <List disablePadding component={Box} display="flex">
          {menus.map((item, index) => (
            <ListItem
              key={index}
              classes={{ root: "MuiListItem-indicators" }}
              component={NavLink}
              to={item?.disabled ? "#" : item?.path}
              sx={{ py: "0.2rem !important", mr: 1 }}
              onMouseEnter={
                item.path === "/services" ? handleServicesMouseEnter : undefined
              }
              onMouseLeave={
                item.path === "/services" ? handleServicesMouseLeave : undefined
              }
            >
              <ListItemText
                sx={{
                  span: {
                    color:
                      item?.path === "/services" && !location?.pathname.includes(item?.path) ?
                        `${theme?.colors?.alpha?.black[70]} !important` : theme?.colors?.alpha?.black[70]
                  }
                }}
                primaryTypographyProps={{
                  noWrap: true,
                  sx: { color: color || "white" },
                  fontWeight: "bold",
                }}
                primary={item?.title}
              />
              {item.path === "/services" && (
                <List
                  className={`dropdown-list ${isServicesDropdownVisible ? "show" : ""
                    }`}
                >
                  {item.children &&
                    item.children.map((subItem, subIndex) => (
                      <ListItem
                        key={subIndex}
                        component={NavLink}
                        to={`${item.path}${subItem.path}`}
                        sx={{ py: "0.2rem !important" }}
                      >
                        <ListItemText
                          primaryTypographyProps={{
                            noWrap: true,
                            sx: { color: color || "white" },
                            fontWeight: "bold",
                          }}
                          primary={subItem.title}
                        />
                      </ListItem>
                    ))}
                </List>
              )}
            </ListItem>
          ))}
        </List>
      </ListWrapper>
    </>
  );
}

export default HeaderMenu;
