import { Box, List, ListItem, ListItemText } from "@mui/material";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { menus } from "./constants";

const ListWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(["color", "fill"])};
            &.MuiListItem-indicators {            
                .MuiListItemText-root {
                    .MuiTypography-root {
                            transition: all .2s;
                            // background: ${theme.colors.primary.main};
                    }
                }

                &.active,
                &:active,
                &:hover {
                    transition: all .2s;
                    .MuiTypography-root{
                      color:${theme?.colors?.primary.main} !important;
                    }
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`
);

function HeaderMenu() {
  return (
    <ListWrapper sx={{ mr: { md: 3, xs: 1 } }}>
      <List disablePadding component={Box} display="flex">
        {menus.map((item, index) => (
          <ListItem
            key={index}
            classes={{ root: "MuiListItem-indicators" }}
            component={NavLink}
            to={item?.value}
            sx={{ py: "0.1rem !important", mr: 1 }}
          >
            {item?.icon}
            <ListItemText
              primaryTypographyProps={{
                noWrap: true,
                sx: { color: "white" },
                fontWeight: "bold",
              }}
              primary={item?.label}
            />
          </ListItem>
        ))}
      </List>
    </ListWrapper>
  );
}

export default HeaderMenu;
