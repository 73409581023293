import { Box, styled, useTheme, Container, Link } from "@mui/material";

import HeaderMenu from "./Menu";
import SocialMedia from "./SocialMedia";

const HeaderTop = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme?.colors?.secondary.main,
        width: "100%",
      }}
    >
      <Container
        maxWidth={"lg"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <HeaderMenu />
        <SocialMedia />
        <Link href="/login" sx={{ ml: 1, textDecoration: 'none', textWrap: 'nowrap' }}>Official Login</Link>
      </Container>
    </Box>
  );
};

export default HeaderTop;
