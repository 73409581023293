import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import AboutIcon from "@mui/icons-material/Info";
import ContactIcon from "@mui/icons-material/ContactPage";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";

type SidebarMenuObject = {
  title?: string;
  path?: string;
  disabled?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  children?: SidebarMenuObject[];
};

export const menus: SidebarMenuObject[] = [
  {
    title: "Home",
    path: "/",
    startIcon: <HomeIcon />,
  },
  {
    title: "Services",
    path: "/services",
    disabled: true,
    startIcon: <SettingsIcon />,
    children: [
      {
        title: "Interior Detailing",
        path: "/interior-detailing",

      },
      {
        title: "Exterior Detailing",
        path: "/exterior-detailing",

      },
      {
        title: "Car Polishing",
        path: "/car-polishing",

      },
      {
        title: "Touch Up Paint",
        path: "/touch-up-paint",

      },
      {
        title: "Undercoating Rust Protection",
        path: "/undercoating-rust-protection",

      },
      {
        title: "Headlight Restoration",
        path: "/headlight-restoration",

      },
    ],
  },
  {
    title: "About Us",
    path: "/about-us",
    startIcon: <AboutIcon />,
  },
  {
    title: "Why Choose us",
    path: "/why-choose",
    startIcon: <HolidayVillageIcon />,
  },

  // {
  //   title: "Blog",
  //   path: "/blog",
  //   startIcon: <AboutIcon />,
  // },
  {
    title: "Contact Us",
    path: "/contact-us",
    startIcon: <ContactIcon />,
  },
];
