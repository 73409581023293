import React, { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";

import { RouteObject } from "react-router";
import SuspenseLoader from "src/components/SuspenseLoader";

import WebLayout from "./layouts/WebLayout";
import UserLayout from "./layouts/UserLayout";
import SidebarLayout from "./layouts/SidebarLayout";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
// Authentications //

// Import
const LoginScreen = Loader(
  lazy(() => import("src/views/authentication/login"))
);

const Booking = Loader(lazy(() => import("src/views/onlineBooking/booking")));
const BookingConfirmation = Loader(lazy(() => import("src/views/onlineBooking/bookingConfirmation")));
const BookingSlots = Loader(lazy(() => import("src/views/onlineBooking/bookingSlots")));

const Home = Loader(lazy(() => import("src/views/website/home")));
const WhyChoose = Loader(lazy(() => import("src/views/website/whyChoose")));
const ContactUs = Loader(lazy(() => import("src/views/website/contactUs")));
const InteriorDetailing = Loader(
  lazy(() => import("src/views/website/interiorDetailing"))
);
const ExteriorDetailing = Loader(
  lazy(() => import("src/views/website/exteriorDetailing"))
);
const CarPolishing = Loader(
  lazy(() => import("src/views/website/carPolishing"))
);
const TouchUpPaint = Loader(
  lazy(() => import("src/views/website/touchUpPaint"))
);
const UndercoatingRustProtection = Loader(
  lazy(() => import("src/views/website/undercoatingRustProtection"))
);
const AboutUs = Loader(
  lazy(() => import("src/views/website/aboutUs"))
);
const HeadlightRestoration = Loader(
  lazy(() => import("src/views/website/headlightRestoration"))
);

// Admin //

const DashboardCrypto = Loader(lazy(() => import("src/views/admin/dashboard")));
const Bookings = Loader(lazy(() => import("src/views/admin/bookings")));
const Categories = Loader(lazy(() => import("src/views/admin/categories"))); // Fixed typo "Cateogries" to "Categories"
const Services = Loader(lazy(() => import("src/views/admin/services")));
const Addons = Loader(lazy(() => import("src/views/admin/addons")));
const DisabledSlots = Loader(lazy(() => import("src/views/admin/disabledSlots")));

const routes = (role?: string, isLoggedIn: boolean = false): RouteObject[] => {
  let routesList: RouteObject[] = [
    {
      path: "",
      element: <WebLayout />,
      children: [
        {
          path: "", // Removed unnecessary path
          element: <Home />,
        },
        {
          path: "why-choose",
          element: <WhyChoose />,
        },
        {
          path: "about-us",
          element: <AboutUs />,
        },
        {
          path: "contact-us",
          element: <ContactUs />,
        },
        {
          path: "/services",
          children: [  // Added children for the "services" path
            {
              path: "interior-detailing",
              element: <InteriorDetailing />,
            },
            {
              path: "exterior-detailing",
              element: <ExteriorDetailing />,
            },
            {
              path: "car-polishing",
              element: <CarPolishing />,
            },
            {
              path: "touch-up-paint",
              element: <TouchUpPaint />,
            },
            {
              path: "undercoating-rust-protection",
              element: <UndercoatingRustProtection />,
            },
            {
              path: "headlight-restoration",
              element: <HeadlightRestoration />,
            },
          ],
        },
      ],
    },
    {
      path: "",
      element: <UserLayout />,
      children: [
        {
          path: "booking",
          element: <Booking />,
        },
        {
          path: "booking-confirmation",
          element: <BookingConfirmation />,
        },
        {
          path: "choose-slot",
          element: <BookingSlots />,
        },
      ],
    },
  ];

  if (!isLoggedIn) {
    routesList = [
      ...routesList,
      {
        path: "admin/*",
        element: <Navigate to="/login" replace />,
      },
      {
        path: "login",
        element: <LoginScreen />,
      },
    ];
  }

  if (isLoggedIn && role === "admin") {
    routesList = [
      {
        path: "login",
        element: <Navigate to="/admin/bookings" replace />,
      },
      ...routesList,
      {
        path: "admin",
        element: <Navigate to="bookings" replace />,
      },
      {
        path: "admin",
        element: <SidebarLayout />,
        children: [
          {
            path: "bookings",
            element: <Bookings />,
          },
          {
            path: "",
            children: [
              {
                path: "categories",
                element: <Categories />,
              },
              {
                path: "services",
                element: <Services />,
              },
              {
                path: "addons",
                element: <Addons />,
              },
              {
                path: "disabled-slots",
                element: <DisabledSlots />,
              },
            ],
          },
        ],
      },
    ];
  }

  return routesList;
};

export default routes;