import { useContext, useState } from "react";

import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
  useTheme,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Link,
} from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";
import { SidebarContext } from "src/contexts/SidebarContext";
import { menus } from "../../constants";
import { connect } from "react-redux";
import { authUserProps } from "src/interfaces";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(1)};

  & > .MuiList-root {
    padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
  }

  .MuiListSubheader-root {
    text-transform: uppercase;
    font-weight: bold;
    font-size: ${theme.typography.pxToRem(12)};
    color: ${theme.colors.alpha.trueWhite[50]};
    padding: ${theme.spacing(0, 2.5)};
    line-height: 1.4;
  }
`
);

const MenuItemWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};

            .MuiSvgIcon-root {
              font-size: inherit;
              color: ${theme.colors.alpha.white[30]};
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.white[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
    "transform",
    "opacity",
  ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu({ auth }: { auth: authUserProps }) {
  const { closeSidebar } = useContext(SidebarContext);
  const theme = useTheme();
  const [openMenu, setOpenMenu] = useState<number | null>(null);

  const handleOpenSubMenu = (index: number) => {
    setOpenMenu((prev) => (prev === index ? null : index));
  };

  return (
    <>
      <MenuWrapper>
        {menus.map((item, index) => {
          if (item?.children?.length >= 0) {
            return (
              <MenuItemWrapper key={index}>
                <ListItemButton
                  onClick={() => handleOpenSubMenu(index)}
                  sx={{
                    alignContent: "flexStart",
                    flexDirection: "rowReverse",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: theme?.colors.alpha.white[30],
                      minWidth: "36px",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {item?.startIcon}
                  </ListItemIcon>
                  <ListItemText sx={{ fontSize: 2, fontWeight: 600, }} primary={item?.title} />
                  {openMenu === index ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openMenu === index} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item?.children?.map((subItem, subItemIndex) => (
                      <ListItemButton
                        key={subItemIndex}
                        LinkComponent={Link}
                        onClick={() => closeSidebar()}
                        href={item?.path + subItem?.path}
                        sx={{ pl: 4, flexDirection: "row-reverse", flexWrap: "wrap" }}
                      >
                        <ListItemIcon>{subItem?.startIcon}</ListItemIcon>
                        <ListItemText primary={subItem?.title} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </MenuItemWrapper>
            );
          } else {
            return (
              <MenuItemWrapper key={index}>
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to={item?.path}
                      startIcon={item?.startIcon}
                      endIcon={item?.endIcon}
                    >
                      {item?.title}
                    </Button>
                  </ListItem>
                </List>
              </MenuItemWrapper>
            );
          }
        })}
      </MenuWrapper>
    </>
  );
}

const mapStateToProps = ({ auth }) => ({ auth });
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
