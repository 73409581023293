import FacebookIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
export const Footerlinks = [
  {
    label: "Home",
    path: "/",
  },
  {
    label: "Services",
    value: "/services/interior-detailing",
  },
  {
    label: "Why Choose us",
    value: "/why-choose",
  },
  {
    label: "About Us",
    value: "/about-us",
  },
  {
    label: "Contact Us",
    value: "/contact-us",
  },
]

export const Buttonlinks = [
  {
    label: "MAKE AN APPOINTMENT",
    value: "/booking",
  },
  
];

export const serviceslinks = [

  {
    label: "Interior Detailing",
    value: "/services/interior-detailing",
  },
  {
    label: "Exterior Detailing & Paint Correction",
    value: "/services/exterior-detailing",
  },
  {
    label: "Headlighr Restoration",
    value: "/services/headlight-restoration",
  },
  {
    label: "Window Tinting",
    value: "/services/interior-detailing",
  },
];
// export * from "src/common/constants";

export const socialMedia = [
  {
    icon: <FacebookIcon />,
    value: "https://www.facebook.com/TBrosAutoDetailing/",
  },
  {
    icon: <InstagramIcon />,
    value: "https://www.instagram.com/",
  },
  {
    icon: <YouTubeIcon />,
    value: "https://www.youtube.com/",
  },
];

export const inputs = [
  {
    key: "name",
    label: "Name",
    type: "text",
    required: true,
  },
  {
    key: "email",
    label: "Email ID",
    type: "text",
    required: true,
  },
  {
    key: "chooseService",
    label: "Choose Service",
    type: "chooseservicOnly",
    required: true,
    minLength: 10,
    maxLength: 10,
  },
  {
    key: "phone",
    label: "Phone No.",
    type: "numberOnly",
    required: true,
    minLength: 10,
    maxLength: 10,
  },
  {
    key: "message",
    label: "Message",
    type: "textarea",
    required: true,
  },
];
