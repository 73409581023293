import FacebookIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
export const socialMedia = [
  {
    icon: <FacebookIcon fontSize="small" />,
    value: "https://www.facebook.com/TBrosAutoDetailing/",
  },
  {
    icon: <InstagramIcon fontSize="small" />,
    value: "https://www.instagram.com/",
  },
  {
    icon: <YouTubeIcon fontSize="small" />,
    value: "https://www.youtube.com/",
  },
];
