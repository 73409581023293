import { addOnProps, bookingProps, categoryProps, disabledSlotItemProps, serviceProps } from "src/interfaces";
import { HANDLE_ADD_DISABLED_SLOT, HANDLE_DELETE_DISABLED_SLOT, HANDLE_LOAD_All_DISABLED_SLOTS, HANDLE_LOAD_All_SERVICES, HANDLE_LOAD_BOOKINGS, HANDLE_UPDATE_ADDON_PRICE, HANDLE_UPDATE_BOOKING_STATUS, HANDLE_UPDATE_DISABLED_SLOT, HANDLE_UPDATE_SERVICE_PRICE, SET_All_DISABLED_SLOTS, SET_All_SERVICES, SET_BOOKINGS } from "./types";

export const fetchBookings = () => {
  return {
    type: HANDLE_LOAD_BOOKINGS,
  };
};

export const updateBookingStatus = (payload: { id: string, status: string }) => {
  return {
    type: HANDLE_UPDATE_BOOKING_STATUS,
    payload
  };
};

export const setBookings = (payload: bookingProps[]) => {
  return {
    type: SET_BOOKINGS,
    payload,
  };
};

export const fetchAllServices = () => {
  return {
    type: HANDLE_LOAD_All_SERVICES,
  };
};

export const setAllServices = (payload: categoryProps[]) => {
  return {
    type: SET_All_SERVICES,
    payload,
  };
};

export const updateService = (payload: serviceProps) => {
  return {
    type: HANDLE_UPDATE_SERVICE_PRICE,
    payload,
  };
};

export const updateAddOn = (payload: addOnProps) => {
  return {
    type: HANDLE_UPDATE_ADDON_PRICE,
    payload,
  };
};

export const fetchAllDisabledSlots = () => {
  return {
    type: HANDLE_LOAD_All_DISABLED_SLOTS,
  };
};

export const setAllDisabledSlots = (payload: disabledSlotItemProps[]) => {
  return {
    type: SET_All_DISABLED_SLOTS,
    payload,
  };
};

export const addDisabledSlot = (payload: disabledSlotItemProps) => {
  return {
    type: HANDLE_ADD_DISABLED_SLOT,
    payload,
  };
};

export const updateDisabledSlot = (payload: disabledSlotItemProps) => {
  return {
    type: HANDLE_UPDATE_DISABLED_SLOT,
    payload,
  };
};

export const deleteDisabledSlot = (payload: { id: string }) => {
  return {
    type: HANDLE_DELETE_DISABLED_SLOT,
    payload,
  };
};

