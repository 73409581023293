import { useContext } from "react";

import {
  Box,
  IconButton,
  Tooltip,
  styled,
  useTheme,
  Container,
  Button,
  Typography,
  Link,
} from "@mui/material";
import { SidebarContext } from "src/contexts/SidebarContext";

import Logo from "src/components/LogoDark";
import HeaderMenu from "./Menu";
import HeaderTop from "./HeaderTop";
import MenuIcon from "@mui/icons-material/Menu";
import PlusIcon from "@mui/icons-material/Add";

const Header = () => {
  const isMobile = document?.body?.clientWidth < 991;
  const headerBackground = isMobile
    ? `background-image:url("/assets/img/search-header-bg-3.png");background-size:cover`
    : "";

  const HeaderWrapper = styled(Box)(
    ({ theme }) => `
          color: ${theme?.user?.header.textColor};
          right: 0;
          z-index: 6;
          ${headerBackground};
          // backdrop-filter: blur(3px);
          position: ${"relative"};
          justify-content: space-between;
          width: 100%;
          // @media (min-width: ${theme.breakpoints.values.lg}px) {
          //     left: ${theme?.user?.sidebar.width};
          //     width: auto;
          // }
  `
  );
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();
  return (
    <Box>
      <HeaderTop />
      <HeaderWrapper
        alignItems="center"
        sx={{
          height: { md: theme?.user?.header?.height },
        }}
      >
        <Box
          sx={{
            backgroundColor: {
              md: theme?.colors?.alpha?.white[100],
              position: "relative",
            },
            width: "100%",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              backgroundColor: theme?.colors?.primary.main,
              width: { xl: "30%", lg: "25%", md: "18%", sm: "30%", xs: "25%" },

              overflow: "hidden",
              height: "100%",
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                borderRight: "solid 45px rgb(255 255 255)",
                borderTop: `solid 105px transparent`,
                borderBottom: "solid -2px transparent",
                position: "absolute",
                backgroundColor: theme?.colors?.primary.main,
                width: "50px",
                height: "100%",
                top: 0,
                right: 0,
              }}
            />
          </Box>
          <Container
            maxWidth={"lg"}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                zIndex: 9,
                width: { md: "15%" },
              }}
            >
              <Logo width={"100%"} />
            </Box>
            <HeaderMenu color={theme?.colors?.alpha?.black[70]} />

            <Button
              variant="contained"
              LinkComponent={Link}
              href="/booking"
              sx={{
                ml: { xs: "auto", md: "unset" },
                mr: { xs: 1, md: 0 },
                background: theme?.colors?.gradients?.primary,
                "*": { color: theme?.colors?.secondary?.main },
                py: { md: 1.5, xs: 1 },
                px: { md: 2, xs: 1 },
                borderRadius: 0.5,
                flexShrink: 0,
              }}
            >
              <Typography
                component="span"
                sx={{ display: { xs: "none", md: "block" }, fontWeight: "600" }}
              >
                MAKE AN APPOINTMENT
              </Typography>
              <Typography
                component="span"
                sx={{ display: { xs: "block", md: "none" }, fontWeight: "600" }}
              >
                <PlusIcon /> APPOINTMENT
              </Typography>
            </Button>

            <Box sx={{ display: { md: "none" } }}>
              <Tooltip arrow title="Toggle Menu">
                <IconButton color="primary" onClick={toggleSidebar}>
                  <MenuIcon color="secondary" />
                </IconButton>
              </Tooltip>
            </Box>
          </Container>
        </Box>
      </HeaderWrapper>
    </Box>
  );
};

export default Header;
