import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";
import { SidebarProvider } from "src/contexts/SidebarContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ReduxProvider from "./store/config";
import ThemeProvider from "./theme/ThemeProvider";

function AppProviders({ children }) {
  return (
    <HelmetProvider>
      <SidebarProvider>
        <ReduxProvider>
          <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Router>
                  {children}
                </Router>
            </LocalizationProvider>
          </ThemeProvider>
        </ReduxProvider>
      </SidebarProvider>
    </HelmetProvider>
  );
}
export default AppProviders;
